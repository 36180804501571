<template>
  <div class="ms-container-wrapper">
    <div class="ms-left">
      <router-link to="/blog">
        <div
            class="ms-section bg_image "
            :style="{ backgroundImage: 'url(' + src + ')' }"
        ></div>
      </router-link>
    </div>
    <div class="ms-right">
      <div class="ms-section ms-table">
        <div
          class="slipt-content-inner ms-tableCell award-content rn-plr rn-ptb-100"
        >
          <div class="content">
            <h2 class="section-title"></h2>

            <ul class="rn-award-list">
              <li v-for="(award, i) in awardContent" :key="i">
                <a :href="award.url" target="_blank"
                  >{{ award.name }} <span>- {{ award.position }}</span></a
                >
                {{ award.year }}
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        src: require("../../assets/img/slider/人员照片.jpg"),
        awardContent: [
          {
            url: "",
            name: "覆盖城市",
            position: "Cover",
            year: "烟台、青岛、威海",
          },
          {
            url: "",
            name: "服务网点",
            position: "Site",
            year: "3000+",
          },
          {
            url: "",
            name: "业务人员",
            position: "Sales",
            year: "20+",
          }
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
